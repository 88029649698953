import { Route } from "@angular/router";
import { NavigableModel } from "@fg-services/navigation-context/segments/navigation-context-segment";
import { AuthGuard } from "@fg-shared/guards/auth.guard";
import { OverviewComponent } from "./cp-overview/cp-overview";
import { ProfileComponent } from "./cp-profile/cp-profile";
import { CalenderComponent } from "./calender/calender.component";
import { PendingChangesGuard } from "@fg-shared/guards/pending-changes-guard";
import { CreatorPromosComponent } from "./creator-promos/CreatorPromosComponent";
import { TeamMembersComponent } from "./team-members/team-members.component";

export const cpRoute : Route  = {
    path: 'cp',
    data: {segmentName : NavigableModel.Creator, app: 'cp'},
    canActivate: [AuthGuard],
    children:[
        {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full',
            data:{
                Label:'Creator Profile',
                showButton: true,
            }
        },
        {
            path: 'overview',
            pathMatch: 'full',
            component: OverviewComponent,
            data:{
                Label:'Overview',
                showButton: true,
            }
        },
        {
            path:'profile',
            pathMatch:'full',
            component: ProfileComponent,
            canDeactivate: [PendingChangesGuard],
            data:{
                Label:'Creator Profile',
                showButton : true,
            }
        },
        {
            path:'new',
            pathMatch:'full',
            component: ProfileComponent,
            canDeactivate: [PendingChangesGuard],
            data:{
                Label:'Creator Profile',
                showButton : true,
            }
        },
        {
            path:'payments',
            data:{
                Label:'Payments',
            },
            loadChildren:()=> import('./payments/payments.module').then(m=>m.PaymentModule),
            // canActivate: [AuthGuard]
        },
        {
            path:'paid',
            data:{
                Label: 'Paid Content',
            },
            loadChildren:() => import('./paidContent/paidContent.module').then(m=>m.paidContentModule)
        },
        {
            path:'polls',
            data:{
                Label: 'Polls',
            },
            loadChildren: () => import('./polls/polls.module').then(m=>m.PollsModule)
        },
        {
            path:"promos",
            data:{
                Label: 'Promos'
            },
            loadChildren:()=> import('./creator-promos/promos.module').then(m=>m.PromoModule),
        },
        {
            path:'posts',
            data:{
                Label:'Posts',
            },
            loadChildren:()=> import('./posts/post.module').then(m=>m.PostModule),
        },
        {
            path:'calendar',
            component:CalenderComponent,
            pathMatch:'full',
            data:{
                Label:'Calendar',
            },
        },
        {
            path:'mode',
            data:{
                Label:'Moderation'
            },
            loadChildren: ()=> import('./moderation/moderation.module').then(m=>m.moderationModule),
        },
        {
            path:'communities',
            data:{
                Label: 'Communities'
            },
            loadChildren:()=> import('./communities/communites.module').then(m=>m.CommunitiyModule)
        },
        {
            path:'notifications',
            data:{
                Label:'Notifications',
            },
            loadChildren:()=> import('./notifications/notification.module').then(m=>m.CreatorNotificationModule)
        },
        {
            path:'team-members',
            data:{
                Label:'Team Members',
            },
            component: TeamMembersComponent
        },
        {
            path:'contests',
            data:{
                Label: 'Contests',
            },
            loadChildren:()=> import('./creator-contests/creator-contest.module').then(m=>m.CreatorContestModule)
        },
        {
            path:'analytics',
            data:{
                Label: 'Analytics',
            },
            loadChildren:()=> import('./analytics/analytics.module').then(m=>m.AnalyticsModule)
        }
    ]
}