<div
  *ngIf="!isSubscriber; else nonSubscriberContent"
  (click)="onLayoutClick()"
  #layoutContainer
  class="layout-container"
  [ngClass]="{
    'menu-layout-static': !isOverlay(),
    'menu-layout-overlay': isOverlay(),
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-horizontal': isHorizontal(),
    'menu-layout-slim': isSlim(),
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive
  }"
>
  <fg-topbar></fg-topbar>
  <ng-container>
    <div class="layout-menu cplayout-menu-dark" (click)="onMenuClick()">
      <div class="p-grid p-justify-around	p-align-center p-nogutter layout-menu-cheader">
        <!-- <i (click)="onMenuButtonClick()" class="p-text-center p-col-2 pi pi-times pointer"></i> -->
        <img class="p-col-5 app-logo" [src]="logoEventologysvg" (click)="onMenuButtonClick()" />
        <div *ngIf="user?.userType !== 'subscriber'">Creator Mode</div>
      </div>

      <!-- creator profile if he is a creator or both -->
      <div #target class="p-grid p-justify-even	p-align-center p-nogutter layout-menu-pheader cursorPointer" (click)="op.show($event, target)">
        <div id="topbar-menu-button" *ngIf="user?.id && user?.userType !== 'subscriber'">
          <div *ngIf="creator?.profileMedia?.url">
            <img [src]="creator.profileMedia?.url" class="profile-nav" />
          </div>
        </div>
        <div *ngIf="user?.userType !== 'subscriber'">{{ creator?.firstName }}</div>
        <div *ngIf="user?.userType === 'both'">
          <i (click)="op.show($event, target)" class="pi pi-chevron-down"></i>
        </div>
      </div>

      <div
        #cpoverlayTarget
        class="p-grid p-justify-even	p-align-center p-nogutter layout-menu-cheader layout-menu-menuheader"
      >
        <img
          *ngIf="currentApp?.asset"
          class="profile-nav"
          [src]="currentApp.asset"
          (click)="creatorapps.show($event, cpoverlayTarget)"
        />
        <div>{{ currentApp?.subtitle }}</div>
        <div>
          <i class="pi pi-chevron-down" (click)="creatorapps.show($event, cpoverlayTarget)"></i>
        </div>
      </div>
      <div #layoutMenuScroller class="cpnano">
        <div class="cpnano-content menu-scroll-content" style="min-height: 880px;">
          <app-menu [reset]="resetMenu"></app-menu>
        </div>
      </div>
      <div class="p-grid p-justify-even	p-align-center p-nogutter cpfooter" (click)="newWindow()">
        <i class="pi pi-arrow-left"></i>
        <img [src]="(user?.profilePhoto.sizes)[0]?.url" class="profile-nav" />
        <label>Back to Account Home</label>
      </div>
    </div>
  </ng-container>

  <div class="layout-main">
    <router-outlet></router-outlet>
    <p-toast [styleClass]="uiService.styleClass" key="global" class="p-col-12"></p-toast>
  </div>
  <div class="layout-mask"></div>
</div>
<fg-loading></fg-loading>
<custom-confirm-dialog
  [style]="{ overflowWrap: 'break-word', width: 'auto' }"
></custom-confirm-dialog>
<p-overlayPanel #creatorapps styleClass="op-apps">
  <ul>
    <li class="input-container app-row" *ngIf="topBarVisible">
      <div class="input-cell">
        <label>YOUR APPS</label>
      </div>
    </li>
    <li
      *ngFor="let app of allApps"
      class="input-container app-list"
      [routerLink]="[app.route]"
      (click)="creatorapps.hide()"
    >
      <div class="input-cell">
        <div class="inline-flex">
          <div class="app-name p-grid p-align-center p-nogutter">
            <img [src]="app.asset" />
            <div>
              <label>{{ app.name }}</label
              ><br />
              <span>{{ app.subtitle }}</span>
            </div>
          </div>
          <div *ngIf="currentApp === app" class="p-chkbox-box p-component p-default p-active">
            <i
              #cpoverlayTarget
              class="p-chkbox-icon pi pi-check"
              (click)="creatorapps.show($event, cpoverlayTarget)"
            ></i>
          </div>
        </div>
      </div>
    </li>
  </ul>
</p-overlayPanel>

<p-overlayPanel #op class="op-apps">
  <ul>
    <li
      *ngFor="let creator of creators"
      class="input-container app-list p-card"
      (click)="op.hide()"
      (click)="addCreator(creator)"
    >
      <div class="input-cell">
        <div class="inline-flex ">
          <div class="app-name p-grid p-align-center p-nogutter">
            <div *ngIf="creator?.profileMedia?.url; else placeholder" class="p-col-4">
              <img [src]="creator?.profileMedia?.url" />
            </div>
            <ng-template #placeholder>
              <div class="image-placeholder p-col-4"></div>
            </ng-template>
            <label class="p-col">{{ creator?.firstName }}</label>
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="input-container app-list " (click)="op.hide()" (click)="createNewCreator()">
      <div class="input-cell">
        <div class="inline-flex">
          <i class="link-color pi pi-plus "> Create Profile</i>
        </div>
      </div>
    </li> -->
  </ul>
</p-overlayPanel>



<ng-template #nonSubscriberContent>
<div
  (click)="onLayoutClick()"
  class="layout-container"
  [ngClass]="{
    'menu-layout-static': !isOverlay(),
    'menu-layout-overlay': isOverlay(),
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-horizontal': isHorizontal(),
    'menu-layout-slim': isSlim(),
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive
  }"
>
  <fg-topbar></fg-topbar>
  <div class="layout-menu layout-menu-dark" (click)="onMenuClick()">
    <div class="p-grid p-justify-even	p-align-center p-nogutter layout-menu-header">
      <i (click)="onMenuButtonClick()" class="p-text-center p-col-2 pi pi-times pointer"></i>
      <img
        *ngIf="currentApp?.logo"
        class="p-col-7 app-logo"
        [src]="currentApp.logo"
        (click)="apps.show($event, overlayTarget)"
      />
      <div class="p-col-1">
        <i #overlayTarget class="pi pi-chevron-down" (click)="apps.show($event, overlayTarget)"></i>
      </div>
    </div>
    <div #layoutMenuScroller class="nano">
      <div class="nano-content menu-scroll-content">
        <app-menu [reset]="resetMenu"></app-menu>
      </div>
    </div>
  </div>
  <div class="layout-main">
    <router-outlet></router-outlet>
    <p-toast [styleClass]="uiService.styleClass" key="global" class="p-col-12"></p-toast>
  </div>
  <div class="layout-mask"></div>
</div>
</ng-template>
<fg-loading></fg-loading>
<custom-confirm-dialog
  [style]="{ overflowWrap: 'break-word', width: 'auto' }"
></custom-confirm-dialog>
<p-overlayPanel #apps styleClass="op-apps">
  <ul>
    <li class="input-container app-row">
      <div class="input-cell">
        <label>YOUR APPS</label>
      </div>
    </li>
    <li
      *ngFor="let app of allApps"
      class="input-container app-row"
      [routerLink]="[app.route]"
      (click)="apps.hide()"
    >
      <div class="input-cell">
        <div class="inline-flex">
          <div class="app-name">
            <label>{{ app.name }}</label
            ><br />
            <span>{{ app.subtitle }}</span>
          </div>
          <div *ngIf="currentApp === app" class="p-chkbox-box p-component p-default p-active">
            <i class="p-chkbox-icon pi pi-check" ></i>
          </div>
        </div>
      </div>
    </li>
    <hr />
    <li class="input-container app-row" [routerLink]="['/']" (click)="apps.hide()">
      <div class="input-cell">
        <label><i class="pi pi-arrow-left"></i>Back to Console</label>
      </div>
    </li>
  </ul>
</p-overlayPanel>

