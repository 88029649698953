import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';

@Component({
  selector: 'search-selective-exhibitor',
  templateUrl: './search-selective-exhibitor.component.html',
  styleUrls: ['./search-selective-exhibitor.component.scss']
})
export class SearchSelectiveExhibitorComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() createNewText: string;
  @Output() addExhibitor = new EventEmitter<ConventionExhibitor>();
  @Input() title = 'Search Exhibitor';
  @Output() queryChange = new EventEmitter<string>();
  filteredExhibitors: (ConventionExhibitor | string)[] = [];
  exhibitor = '';
  filteredCategories: any[] = [];
  params: any;
  query: string = '';
  allExhibitors: ConventionExhibitor[] = [];

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.loadExhibitors();
  }

  loadExhibitors() {
    this.loader().subscribe(
      (res: any) => {
        this.allExhibitors = res.items;
        this.filteredExhibitors = [...this.allExhibitors];
      },
      (error) => {
        console.error('Error loading exhibitors:', error);
      }
    );
  }

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.query = params.query.toLowerCase();
      this.queryChange.emit(this.query);
      
      this.filteredExhibitors = this.allExhibitors.filter(exhibitor => 
        exhibitor.name.toLowerCase().includes(this.query)
      );

      if (!this.filteredExhibitors) {
        this.filteredExhibitors.push(this.createNewText);
      }
    }
  }

  loader() {
    return this.apiService.conventions.getConventionExhibitors(
      this.conventionId,
      null,
      null,
      1000
    );
  }
}